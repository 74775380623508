/* eslint-disable vue/no-side-effects-in-computed-properties */
<script>
import Layout from "../layouts/main";
import appConfig from "@/app.config";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout},
  data() {
    return {
      title: "",
    }
  },
  computed : {
    loading(){
        return this.$store.getters['users/loading'];
    },
    counter(){
        return this.$store.getters['users/counter'];
    },
  },
  created(){
  },
  mounted(){
    this.$store.dispatch("users/counter")
  },
  methods : {
  }

};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">Dashboard </h4>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">Tanggal : 05-Apr-2021 | Pukul : 03:35:34 , Selamat Malam</li>
            </ol>
          </div>
        </div>
            <div class="row" >
              <div class="col-md-4">
                <b-overlay :show="loading" rounded="sm">
                    <div class="card mini-stats-wid"  with overlay :aria-hidden="loading ? 'true' : null">
                        <div class="card-body">
                            <div class="media">
                                <div class="media-body">
                                    <p class="text-muted font-weight-medium">Total User</p>
                                    <h4 class="mb-0">{{counter.total}}</h4>
                                </div>

                                <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                    <span class="avatar-title">
                                        <i :class="`bx bx-group font-size-24`"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-overlay>
              </div>
              <div class="col-md-4">
                <b-overlay :show="loading" rounded="sm">
                    <div class="card mini-stats-wid"  with overlay :aria-hidden="loading ? 'true' : null">
                        <div class="card-body">
                            <div class="media">
                                <div class="media-body">
                                    <p class="text-muted font-weight-medium">Free User</p>
                                    <h4 class="mb-0">{{counter.freeUser}}</h4>
                                </div>

                                <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                    <span class="avatar-title">
                                        <i :class="`bx bx-group font-size-24`"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-overlay>
              </div>
              <div class="col-md-4">
                <b-overlay :show="loading" rounded="sm">
                    <div class="card mini-stats-wid"  with overlay :aria-hidden="loading ? 'true' : null">
                        <div class="card-body">
                            <div class="media">
                                <div class="media-body">
                                    <p class="text-muted font-weight-medium">Paid User</p>
                                    <h4 class="mb-0">{{counter.paidUser}}</h4>
                                </div>

                                <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                    <span class="avatar-title success">
                                        <i :class="`bx bx-group font-size-24`"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-overlay>
              </div>
              <div class="col-md-4">
                <b-overlay :show="loading" rounded="sm">
                    <div class="card mini-stats-wid"  with overlay :aria-hidden="loading ? 'true' : null">
                        <div class="card-body">
                            <div class="media">
                                <div class="media-body">
                                    <p class="text-muted font-weight-medium">Active User</p>
                                    <h4 class="mb-0">{{counter.activeUser}}</h4>
                                </div>

                                <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                    <span class="avatar-title success">
                                        <i :class="`bx bx-group font-size-24`"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-overlay>
              </div>
              <div class="col-md-4">
                <b-overlay :show="loading" rounded="sm">
                    <div class="card mini-stats-wid"  with overlay :aria-hidden="loading ? 'true' : null">
                        <div class="card-body">
                            <div class="media">
                                <div class="media-body">
                                    <p class="text-muted font-weight-medium">InActive User</p>
                                    <h4 class="mb-0">{{counter.nonActiveUser}}</h4>
                                </div>

                                <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-danger">
                                    <span class="avatar-title danger">
                                        <i :class="`bx bx-group font-size-24`"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-overlay>
              </div>
            </div>
      </div>
    </div>
  </Layout>
</template>
